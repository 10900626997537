@import '../../node_modules/@stronghold/bootstrap/scss/bootstrap';

.report-button{
  display: flex;
  width: 40px;
  height: 40px;
  margin-right: 0px;
  margin-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: $dark-grey-1;
  border-radius: 50%;
  background-color: $dark-grey-3;
}

.dropdown-menu{
  border-radius: 3px;
  box-shadow: 2px 8px 18px 0 rgba(6, 8, 16, 0.06)
}

.show-scaled {
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  transform: scale(1.4);
}

.checkmark-line.top {
  width: 18px;
  -webkit-transform: translate(4px, 0px) rotate(
                  -60deg
  );
  -ms-transform: translate(4px, 0px) rotate(-60deg);
  transform: translate(4px, 0px) rotate(
                  -60deg
  );
}

.checkmark-line {
  width: 10px;
  height: 2px;
  background-color: #909090;
  -webkit-transform: translate(0px, 6px) rotate(
                  40deg
  );
  -ms-transform: translate(0px, 6px) rotate(40deg);
  transform: translate(0px, 6px) rotate(
                  40deg
  );
}

.font-size-lg {
  font-size: 18px !important;  
}

.search-btn {
  font-weight: 500 !important;
  font-size: 18px !important;
}

:focus-visible {
  outline: 0;
  box-shadow: none;
}

.opacity-1 {
  opacity: 0.1;
}

.new-feature {
  position: static;
  z-index: 2;
  display: inline-block;
  padding: 3px 12px;
  border-style: none;
  border-width: 1px;
  border-color: $primary-2;
  border-radius: 1px;
  background-color: #3c70b7;
  color: $white;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  text-align: left;
}

.edit-circle-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: $dark-grey-2 !important;
  cursor: pointer;
}

body {
  background-color: #fff;
}

.modal-content {
  width: 95% !important;
}

.view-link {
  .link-icon {
    transform: translate(0px, 0%);
    transition-duration: 1s;
  }
}

.view-link:hover {
  cursor: pointer;
  .link-icon {
    transform: translate(5px, 0%);
    transition-duration: 1s;
  }
}

// Stronghold Direct 

.step-grow {
  animation: grow .25s;
}

@keyframes grow {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

// Typography
.circle-border {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  padding-right: 1px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #909090;
  border-radius: 50%;
  color: #909090;
  font-weight: 700;
}

.steps-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.step-dash {
  position: relative;
  width: 50px;
  height: 2px;
  margin-right: 6px;
  margin-left: 6px;
  background-color: #909090;
}

.border-2 {
  border: 2px solid $dark !important;
}

.dropdown-toggle {
  justify-content: space-between !important;
}

.dropdown-toggle:after {
  margin-top: 0.5em !important;
}
@media screen and (max-width: 768px){
  h3 {
    font-size: 2rem !important;
    max-width: 100% !important;
    line-height: 40px !important;
    text-align: center !important;
  }
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

// Table

.font-size-normal {
  font-size: 1rem !important;
}

th {
  font-size: 1.25rem !important;
  text-transform: capitalize !important;
}

td {
  font-size: 1.15rem !important;
  font-weight: 600 !important;
}

.table-lg th, .table-lg td {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

tr {
  .link-icon {
    transform: translate(0px, 0%);
    transition-duration: 1s;
  }
}

tr:hover {
  cursor: pointer;
  .link-icon {
    transform: translate(5px, 0%);
    transition-duration: 1s;
  }
}

// Tab Pane
.fade-in {
    animation: fadeIn .5s;
    -webkit-animation: fadeIn .5s;
    -moz-animation: fadeIn .5s;
    -o-animation: fadeIn .5s;
    -ms-animation: fadeIn .5s;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

// Detail Card
.detail-card {
  @extend .card-body;
  min-height: 9rem;
  background-color: $gray-100;
  font-size: 20px;
}

// Balance Transfer Card
// Detail Card
.balance-card {
  @extend .detail-card;
  min-height: 24rem !important;
}

// LinkTiles
 
a:hover {
  text-decoration: none !important
}

a {
  font-size: 18px !important;
}

button:hover {
  text-decoration: none !important
}

.tile-link {
  -webkit-transform: scale(1.00);
  -ms-transform: scale(1.00);
  transform: scale(1.00);
  transition-duration: 0.5s;
  .link-icon {
    transform: translate(0px, 0%);
    transition-duration: 1s;
  }
}

.tile-link:hover {
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
  cursor: pointer;
  transition-duration: 0.5s;
  .link-icon {
    transform: translate(10px, 0%);
    transition-duration: 1s;
  }
}
.bg-grey-150 {
  background-color: $gray-150;
}

.bg-grey-100 {
  background-color: $gray-100;
}

.env-badge {
  margin-left: 0px;
  padding: 4px 6px;
  border-radius: 2px;
  background-color: #fce7c0;
  color: #060810;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}

.id-badge {
  background-color: $dark-grey-2;
  color: $gray-600;
  padding: 4px 10px;
  border-radius: 2px;
  line-height: 20px;
}

.text-small {
  font-size: 14px !important;
  line-height: 16px !important;
}

// Navbar
.nav-link {
  color: inherit !important;
}

.top-nav {
  background-color: #060810 !important;
  background-image: linear-gradient(171deg, #060810, rgba(11, 41, 147, 0.3)) !important;
}

.md-nav-bar {
  padding: 8px 8% !important;
}

.bottom-shadow-box {
  box-shadow: 0 4px 10px 0px rgba(6, 8, 16, 0.04);
}

.border-bottom-gray {
  border-bottom: 3px solid $gray-150 !important;
}

.border-bottom-gray-sm {
  border-bottom: 1px solid $gray-150 !important;
}

.border-top-gray {
  border-top: 3px solid $gray-150 !important;
}

.border-top-gray-sm {
  border-top: 1px solid $gray-150 !important;
}

.nav-bar-brand {
  left: 50%;
}

.nav-item {
 list-style: none !important;
}

// Alert
.card > .alert {
  @extend .m-3, .text-center, .p-5;
}

// Button
.btn-gray-150 {
  background-color: $gray-150 !important;
  color: $dark !important;
  border: none !important;
}

.btn-gray-100 {
  background-color: $gray-100 !important;
  color: $gray-600 !important;
  border: none !important;
}

.hover-pointer:hover {
  cursor: pointer;
  .link-icon {
    transform: translate(10px, 0%);
    transition-duration: 1s;
  }
}

.hover-text-cursor {
  cursor: text !important;
}

.blurry-text {
  color: transparent;
  text-blue-3adow: 0 0 5px #e83e8c;
}

.no-hover:hover {
  background-color : #ffffff;
}

.btn-key {
  border: 1px solid $gray-300;
  border-radius: 0.3em;
  z-index: 99;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.dropdown-input {
  border-color: rgb(206, 212, 218);
  background-color: rgb(248, 249, 250);
}

// Switch
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #f0ad4e;
  background-color: #f0ad4e;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #f7f7f9;
  -webkit-transform: translateX(1.2rem);
  transform: translateX(1.2rem);
}

.custom-switch .custom-control-label::after {
  color: inherit !important;
  top: 2px;
  left: calc(-1.5rem + 2px);
  width: calc(1.3rem - 4px);
  height: calc(1.3rem - 4px);
  border-radius: 2rem;
}

.custom-switch .custom-control-label::before {
  color: inherit !important;
  left: -1.5rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-control-label::before {
  position: absolute;
  top: 0rem;
  display: block;
  width: 2.5rem !important;
  height: 1.25rem;
  pointer-events: none;
  content: "";
}

// Tool Tip
.tooltip-inner {
  background-color: white !important;
  color: black;
  border: 1px solid $gray-300;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: white !important;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: white !important;
}


.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: white !important;
}


.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: white !important;
}

// React date picker
.react-datepicker {
  border-color: $border-color !important;
}

.react-datepicker__header {
  border-bottom-color: $border-color !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: $primary !important;
}

.react-datepicker__triangle::before {
  border-bottom-color: $border-color !important;
}

.react-datepicker__year-dropdown--scrollable {
  border-color: $border-color !important;
}

.react-datepicker__year-option--selected_year {
  background-color: $primary !important;
  color: white;

  > span {
    display: none;
  }
}

.react-datepicker-popper {
  z-index: 99 !important;
}

// React select
.react-select-custom {
  @extend .form-control, .h-auto, .p-0;
  > span {
    box-shadow: $input-focus-box-shadow;
  }

  > div {
    border-color: transparent !important;
    box-shadow: 0 0 0 1px transparent !important;
    background-color: $input-bg;
    border-radius: inherit;
    min-height: calc(1.5em + 2rem + 0px);
  }
}

// List button

.list-btn {
  .btn-lg {
    max-height: calc(1.5em + 2rem + 0px) !important;
  }
}

// Nav DropDown
.dropdown-menu {
  z-index: 9999;
}

.dropdown-item {
  transition: all 350ms ease;
}

.nav-icon {
  max-width: 1.0625rem !important;
  max-height: 1.0625rem !important;
  margin-right: 0.625rem !important;
}